import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['state'])
  },
  data () {
    return {
      communicator: null,
      open: false
    }
  },
  methods: {
    async sendPrinterAction (data, contentType, resolve, reject) {
      if (!this.state.peripheralConnector || !this.state.peripheralConnector.isConnected()) {
        await this.$store.dispatch('state/connectPeripheralSocket').catch((error) => {
          reject(error)
        })
        await this.connect().catch((error) => {
          this.$store.commit('state/setMessage', { text: this.$t('peripherals.error.socket_connection'), color: 'error' })
          reject(error)
        })
      }
      if (this.state.peripheralConnector.isConnected()) {
        try {
          this.state.peripheralConnector.send(
            localStorage.getItem('selectedPrinter'),
            'PRINT',
            {
              data,
              contentType
            },
            () => {},
            (payload) => {
              if (resolve) {
                resolve(payload)
              }
            },
            (payload) => {
              if (reject) {
                reject(payload)
              }
            }
          )
        } catch (e) {
          reject(e)
        }
      } else {
        reject(new Error('Could not connect to printer'))
      }
    }
  }
}
